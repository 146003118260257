import React from 'react';
import { Link } from "react-router-dom";
import { redirectTo } from './redirecturl';
//import axios from 'axios';
import API_BASE_URL from '../../config/api';

class ProductSearchAutocomplete extends React.Component {
    tokens = null;
    constructor(props){
        super(props)
        this.state = {
            query: '',
            oldquery: '',
            suggestions: [],
            text: '',
            searchingData: false,
            setShowSuggestions: false,
            clickedSearchData: (localStorage.getItem("searchData") != null && JSON.parse(localStorage.getItem("searchData")).length > 0) ? JSON.parse(localStorage.getItem("searchData")) : [],
            clickedSearchTextBox: false,
            clickCountInSearchBox: 0,
            isSearchProductLoaded: false,
            isBackspacePressed: false,
            displayTotalLocalStorageProd: 15,
            count: 0
        }

        this.handleOnSearchSubmit = this.handleOnSearchSubmit.bind(this);
        this.inputRef = React.createRef();
    }

    handleOuterClick = e => {
        if (!this.inputRef.current.contains(e.target)) {
          this.setState({setShowSuggestions: false, clickedSearchTextBox: false});
        }
    };

    componentDidMount() {
        document.addEventListener("click", this.handleOuterClick);
    }

    handleSearchFormValidation(){
        let searchtxt = this.state.text;
        let searchFormIsValid = true;

        if(!searchtxt){
            searchFormIsValid = false;
        }

        return searchFormIsValid;
    }

    handleOnSearchSubmit(e){
        e.preventDefault();

        if(this.handleSearchFormValidation()){
            let searchtxt = this.state.text;
            return redirectTo("/search/"+searchtxt);
        }
    }

    handleOnProductRedirect(productId, productName, productPrice, productImage, productRegularPrice, allowPurchase, productAvailableQuantity, isStored=false){
        if(!isStored){
            const prodObj = {
                                product_id: productId, 
                                product_title: productName, 
                                product_price: productPrice, 
                                product_image: productImage,
                                product_regular_price: productRegularPrice,
                                allow_purchase: allowPurchase,
                                product_available_quantity: productAvailableQuantity
                            };           
                if (this.state.clickedSearchData.filter(searchArr => searchArr.product_id === prodObj.product_id).length === 0) {
                    this.setState({ clickedSearchData: [...this.state.clickedSearchData, prodObj] }, function() {
                        localStorage.setItem("searchData", JSON.stringify(this.state.clickedSearchData));
                    });
                }
        }
        return redirectTo("/producto/"+productId);
    }

    getInfo = async function () {
        this.setState({searchingData: true})
        let userSearchText = this.state.query;
        //const apiUrl = 'https://www.evisionstore.com/api/home/advanced_search_product_beta.php?keyword='+userSearchText;
        const apiUrl = `${API_BASE_URL}/search?keyword=${userSearchText}`;
        const requestOptions = {
            method: 'GET',
            //body: JSON.stringify({"keyword": userSearchText}),
            //mode: 'cors'
        };

        const tokens = {};
        this.tokens = tokens;

        await fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (this.tokens === tokens) {
                    if(result.code === 200){
                        this.setState({
                            suggestions: result.data,
                            //text: userSearchText,
                            searchingData: false,
                            isSearchProductLoaded: true
                        })
                    }else{
                        this.setState({
                            suggestions: [],
                            //text: userSearchText,
                            searchingData: false,
                            isSearchProductLoaded: true
                        })
                    }
                }
            },
            (error) => {
                this.setState({ error: error });
            }
        )
        // axios.get(`https://evisionstore.com/api/home/search_product.php?keyword=${this.state.query}`)
        //     .then(({ data }) => {
        //         this.setState({
        //             suggestions: data.search_result,
        //             text: this.state.query,
        //             searchingData: false
        //         })
        //     })
    }

    onTextClick = () => {
        let localSearchData = JSON.parse(localStorage.getItem("searchData"));
        let suggestionsData = this.state.suggestions;
        if((localSearchData != null && localSearchData.length > 0) || (suggestionsData != null && suggestionsData.length > 0)){
            this.setState({ setShowSuggestions: true });
        }else{
            this.setState({ setShowSuggestions: false });
        }
    }

    handleKeyDown = (e) => {
        //console.log('User pressed: ', e.key);

        if (e.key === 'Backspace') {
            this.setState({
                isBackspacePressed: true
            });
        }else{
            this.setState({
                isBackspacePressed: false
            });
        }
    };

    onTextChange = (e) => {
        if(e.target.value !== "" && e.target.value.length > 1) { 
            let localSearchData = JSON.parse(localStorage.getItem("searchData"));
            if(localSearchData != null && localSearchData.length > 0){
                localSearchData = JSON.parse(localStorage.getItem("searchData")).length;
            }else{
                localSearchData = 0;
            }
            this.setState({
                query: e.target.value,
                text: e.target.value,
                //suggestions: [],
                setShowSuggestions: true,
                clickCountInSearchBox: 1,
                displayTotalLocalStorageProd: localSearchData
            }, () => {
                //if (!this.state.isBackspacePressed) {
                    //if (this.state.query.length % 2 === 0) {
                        this.getInfo()
                        //let userSearchText = this.state.query;
                        //this.props.searchProductByKeywords(userSearchText);
                    //}
                // }else{
                //    this.setState({
                //         suggestions: this.state.suggestions
                //     }); 
                // }
            })
        } else {
            this.setState({
                text: e.target.value,
                suggestions: [],
                setShowSuggestions: false,
                displayTotalLocalStorageProd: 15
            });
        }
    }

    selectedText(value) {
        this.setState(() => ({
            text: value,
            suggestions: [],
        }))
    }

    renderSuggestions = (isSetShowSuggestions) => {
        let { suggestions, isSearchProductLoaded, displayTotalLocalStorageProd } = this.state;
        const hidden = isSetShowSuggestions ? '' : 'hidden'; 
        let searchData = (localStorage.getItem("searchData") != null && JSON.parse(localStorage.getItem("searchData")).length > 0) ? JSON.parse(localStorage.getItem("searchData")) : [];
        let searchKeywords = (this.state.query).toLowerCase();
        let searchKeywordsUp = (this.state.query).toUpperCase();
        //var data = searchData.filter(item => item.product_id === "895Q7LA");

        if(isSearchProductLoaded){
            return (
                <>
                    {suggestions != null && suggestions.length > 0 ? (
                        suggestions.map((suggestions, index) => (
                           
                            (searchData.filter(searchArr => searchArr.product_id === suggestions.product_id).length === 0) ? (

                            <li key={suggestions.product_id}>
                                <Link to="#" onClick={() => this.handleOnProductRedirect(suggestions.product_id, suggestions.product_title, suggestions.product_price, suggestions.product_image, suggestions.product_regular_price, suggestions.allow_purchase, suggestions.product_available_quantity)}>
                                    <div className="img-sec">
                                        <img className="prod-img" src={suggestions.product_image} alt={suggestions.product_title} />
                                    </div>
                                    <div className="desc-sec">
                                        <p dangerouslySetInnerHTML={{__html: suggestions.product_title }} />
                                        {(() => {
                                            if (suggestions.product_price === '0.00'){
                                                return (<></>)
                                            } else {
                                                return (
                                                    <p>
                                                        <span className="price-span">Precio:</span> 
                                                        <span className="sell-price-span"> ${suggestions.product_price}</span>

                                                        {suggestions.product_regular_price ? (
                                                            <span className="reg-price-span">
                                                                ${suggestions.product_regular_price}
                                                            </span>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </p>
                                                )
                                            }
                                        })()}
                                        {(() => {
                                            if(suggestions.allow_purchase === 1){
                                                if (suggestions.product_available_quantity > 0){
                                                    return (<p className="instock-sec">En Stock</p>)
                                                } else {
                                                    return (
                                                        <p className="outstock-sec">Fuera de stock</p>
                                                    )
                                                }
                                            }else{
                                                return (
                                                    <p className="outstock-sec">Fuera de stock</p>
                                                )
                                            }
                                        })()}
                                    </div>
                                    <div className="sep-sec" />
                                </Link>
                            </li>
                            
                            ) : (
                                <></>
                            )    
                            
                        ))
                    ) : (

                        Object.values(searchData).length !== 0 ? (
                            Object.values(searchData.reverse()).slice(0, displayTotalLocalStorageProd).map((search, index) => (
                                ((search.product_title.replace(/<\/?[^>]+(>|$)/g, "")).toLowerCase().includes(searchKeywords, 0)) ? (
                                    <></>
                                ) : (
                                    (index == 0) ? (
                                        <li>
                                            <p>No se han encontrado resultados!</p>
                                        </li>
                                    ) : (
                                        <></>
                                    )
                                )
                            ))
                        ) : (
                            <li>
                                <p>No se han encontrado resultados!</p>
                            </li>
                        )
                    )
                }
                </>
            );
        }
    }

    render() {
        const { text, displayTotalLocalStorageProd } = this.state;
        const hidden = this.state.setShowSuggestions ? '' : 'hidden';
        let searchData = JSON.parse(localStorage.getItem("searchData"));
        let searchKeywords = (this.state.query).toLowerCase();
        let searchKeywordsUp = (this.state.query).toUpperCase();
        
        return (
            <>
                <div className="search_box_area"  ref={this.inputRef}>
                    <form onSubmit={this.handleOnSearchSubmit}>
                        <input type="text" placeholder="búsqueda" name="search" onChange={this.onTextChange} onKeyDown={this.handleKeyDown} value={text} autoComplete="off" onClick={this.onTextClick} />
                        <button type="submit">
                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/header-search-icon.svg`} alt="Header Search Icon" width="17" height="17" />
                        </button>
                    </form>
                </div>
                {(() => {
                    
                    return (
                        <ul className={`autocomplete ${hidden}`}>
                            {searchData != null && Object.values(searchData).length !== 0 ? (
                                Object.values(searchData.reverse()).slice(0, displayTotalLocalStorageProd).map((search, index) => (
                                    ((search.product_title.replace(/<\/?[^>]+(>|$)/g, "")).toLowerCase().includes(searchKeywords, 0)) ? (
                                        <li key={search.product_id}>
                                            <Link to="#" onClick={() => this.handleOnProductRedirect(search.product_id, search.product_title, search.product_price, search.product_image, search.product_regular_price, search.allow_purchase, search.product_available_quantity, true)}>
                                                <div className="img-sec">
                                                    <img className="prod-img" src={search.product_image} alt={search.product_title} />
                                                </div>
                                                <div className="desc-sec">
                                                    <p dangerouslySetInnerHTML={{__html: (search.product_title.replace(/<\/?[^>]+(>|$)/g, "")).replace(searchKeywordsUp, "<mark>"+searchKeywordsUp+"</mark>") }} />
                                                    {(() => {
                                                    if (search.product_price === '0.00'){
                                                        return (<></>)
                                                    } else {
                                                        return (
                                                            <p>
                                                                <span className="price-span">Precio:</span> 
                                                                <span className="sell-price-span"> ${search.product_price}</span>

                                                                {search.product_regular_price ? (
                                                                    <span className="reg-price-span">
                                                                        ${search.product_regular_price}
                                                                    </span>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </p>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if(search.allow_purchase === 1){
                                                        if (search.product_available_quantity > 0){
                                                            return (<p className="instock-sec">En Stock</p>)
                                                        } else {
                                                            return (
                                                                <p className="outstock-sec">Fuera de stock</p>
                                                            )
                                                        }
                                                    }else{
                                                        return (
                                                            <p className="outstock-sec">Fuera de stock</p>
                                                        )
                                                    }
                                                })()}
                                                </div>
                                                <div className="sep-sec" />
                                            </Link>
                                        </li>
                                    ) : (
                                        <></>
                                    )
                                    ))
                                ) : (
                                    <></>
                                )
                            }
                            {this.renderSuggestions(this.state.setShowSuggestions)}
                        </ul>
                    )
                    
                })()}
            </>
        )
    }
}

export default ProductSearchAutocomplete;