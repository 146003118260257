import React, {Component} from 'react';
import DealSlider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import API_BASE_URL from '../../config/api';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
        />
    );
}

export default class MobileHomePopularSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            topProducts: []
        }
    }

    componentDidMount() {
        const apiUrl = `${API_BASE_URL}/specialproducts`;

        fetch(apiUrl)
        .then(res => res.json())
        .then(
            (result) => {
                //console.log(result)
                this.setState({
                    topProducts: result.data
                });
            },
            (error) => {
                this.setState({ error });
            }
        )
    }

    render(){
        const mobiledelalslidersettings = {
            //dots: true,
            infinite: true,
            autoplay: true,
            speed: 500,
            //rows: 1,
            //slidesPerRow: 2,
            slidesToShow: 2,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 2,
                    dots: true,
                  }
                }
            ]
        };

        const { topProducts } = this.state;

        return(
            <section className="deal-container for-mobile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="product-title">
                                <h2>Más popular {/*<span>Trato especial</span>*/}</h2>
                            </div>
                        </div>
                        <section className="customer-logos slider">
                            <DealSlider {...mobiledelalslidersettings}>
                            
                            {topProducts.length !== 0 ? (
                                topProducts.map((products, index) => (
                                    <div className="slide" key={index}>
                                        <div className="deal-link-item">
                                            <Link to={`/producto/${products.product_id}`}>
                                                <figure>
                                                    <a href="javascript:void(0)" className="wishlist-link">
                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/wishlist-icon.svg`} width="20" height="18" alt="wishlist" />
                                                    </a>
                                                    <img src={products.product_image} alt={products.product_name} />
                                                </figure>
                                                <div className="heading-wrap">
                                                    <h2>{products.product_name}</h2>
                                                    <div className="review"><img src="/images/new-images/icon/star.svg" width="20" height="19" title="star" alt="star" /><span>5.0 | 14 opiniones</span></div>
                                                    <div className="pricecart">
                                                    <div className="price">$ {products.price}</div>
                                                    {/* {products.check_spcl_prc === "yes" ? (
                                                        <div className="price">$ {products.special_price} - <span className="oldprice">$ {products.price}</span></div>
                                                    ) : (
                                                        <div className="price">$ {products.price}</div>
                                                    )} */}
                                                    <a href="javascript:void(0)">
                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                    </a>
                                                    {/* <div className="price">$ {products.price}</div> */}
                                                    
                                                    {/* <h3>Ends in {products.time_ends}</h3> */}
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <></>
                            )}

                            </DealSlider>
                        </section>
                    </div>
                </div>
            </section>
        )
    }
}