import React, {Component} from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class BannerSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            categoryName : '',
            isCategoryLoaded: false
        }
    }

    componentDidMount() {
        let category_id = this.props.match.params.categoryId;
        const apiUrl = 'https://www.evisionstore.com/api/product/get_title_and_image.php';
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({"category_id": category_id})
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    categoryName: result.category_name,
                    categoryImage: result.category_image_url,
                    isCategoryLoaded: true
                });
            },
            (error) => {
                this.setState({ error: error });
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.match.params.categoryId !== this.props.match.params.categoryId) {
            
            this.setState({
                isCategoryLoaded: false
            });

            let category_id = this.props.match.params.categoryId;
            const apiUrl = 'https://www.evisionstore.com/api/product/get_title_and_image.php';
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({"category_id": category_id})
            };
            fetch(apiUrl, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        categoryName: result.category_name,
                        categoryImage: result.category_image_url,
                        isCategoryLoaded: true
                    });
                },
                (error) => {
                    this.setState({ error: error });
                }
            )
        }
    }

    render(){
        const {categoryName, categoryImage} = this.state;  

        return(
            <>
            <section className="banner-container">
                <div className="product-title">{categoryName}</div>
                <figure> 
                <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt={categoryName} />
                {/* {(() => {
                    if (categoryImage !== ''){
                        return ( <img src={categoryImage} alt={categoryName} /> );
                    } else {
                        return ( <img src={`${process.env.PUBLIC_URL}/images/header-bg.jpg`} alt={categoryName} /> );
                    }
                })()} */}
                </figure>		      
            </section>

            <section className="breadcamp">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Link to="/">Home &raquo;</Link> {categoryName}
                    </div>
                </div>
            </div>
            </section>
            </>
        )
        
    }
}

export default withRouter(BannerSection);