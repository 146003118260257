import React, {Component} from 'react';
import BannerSection from './BannerSection';
import BreadcrumbSection from './BreadcrumbSection';
import ProductFilterSection from './ProductFilterSection';
import ProductLeftCategoryMenu from './ProductLeftCategoryMenu';
import ProductRightCategoryList from './ProductRightCategoryList';

export default class LandingBrandShopPage extends Component {

    render(){
        return(
            <div className="wraper">
                <BreadcrumbSection />
                <ProductFilterSection />
                <section className="listing-container">
                    <div className="container">
                        <div className="row">
                            <ProductLeftCategoryMenu />
                            <ProductRightCategoryList />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}