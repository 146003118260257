import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Skeleton from "react-loading-skeleton";
import API_BASE_URL from '../../config/api';

class ProductLeftCategoryMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            cat_products: [],
            catProductsLoaded: false,
            selectedParentCatId: false,
            currentChildCategoryId: ''
        }
    }

    componentDidMount() {
        let categoryId = this.props.match.params.categoryName;
        this.setState({
            currentChildCategoryId: categoryId
        });

        const apiUrl = `${API_BASE_URL}/category`;
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify({"category_slug": categoryId})
        };

        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    selectedParentCatId: result.data.selected_parent_category_id,
                    catProducts: result.data.menu,
                    catProductsLoaded: true
                });
            },
            (error) => {
                this.setState({ error });
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.match.params.categoryName !== this.props.match.params.categoryName) {
            let categoryId = this.props.match.params.categoryName;
            this.setState({
                currentChildCategoryId: categoryId
            });
            
            const apiUrl = `${API_BASE_URL}/category`;
            let requestOptions = {
                method: 'POST',
                body: JSON.stringify({"category_id": categoryId})
            };

            fetch(apiUrl, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        selectedParentCatId: result.data.selected_parent_category_id,
                        catProducts: result.data.menu,
                        catProductsLoaded: true
                    });
                },
                (error) => {
                    this.setState({ error });
                }
            )
        }
    }

    render(){
        const { catProducts, selectedParentCatId, currentChildCategoryId } = this.state;
        
        if(this.state.catProductsLoaded){
            return(
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 desktop catagory-left-panel">
                    <nav className="navigation">
                        <h2>Departamentos</h2>
                        <ul className="mainmenu">
                            {catProducts.map(cat_products => 
                                (cat_products.sub).length ? (
                                    <li className="dropdown-list-menu" key={cat_products.id}> 
                                        {(() => {
                                        if (cat_products.id === selectedParentCatId){  
                                            return <Link to={`/categorias/${cat_products.slug}`} className="menuactive">{cat_products.name}</Link>
                                        } else {
                                            return <Link to={`/categorias/${cat_products.slug}`} >{cat_products.name}</Link>
                                        }
                                        })()}
                                        <ul className="submenu">
                                            {cat_products.sub.map(submenu => (
                                                <li key={submenu.id}>
                                                    {(() => {
                                                    if (submenu.id === currentChildCategoryId){  
                                                        return <Link to={`/categorias/${submenu.slug}`} className="menuactive">{submenu.name}</Link>
                                                    } else {
                                                        return <Link to={`/categorias/${submenu.slug}`} >{submenu.name}</Link>
                                                    }
                                                    })()}
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ) : (
                                    <li key={cat_products.id}> 
                                        {(() => {
                                        if (cat_products.id === selectedParentCatId){  
                                            return <Link to={`/categorias/${cat_products.slug}`} className="menuactive">{cat_products.name}</Link>
                                        } else {
                                            return <Link to={`/categorias/${cat_products.slug}`} >{cat_products.name}</Link>
                                        }
                                        })()} 
                                    </li>
                                )
                            )}
                        </ul>
                    </nav>
                </div>
            )
        } else {
            return(
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 desktop catagory-left-panel">
                    <h2><Skeleton height={50} width={`100%`} /></h2>
                    <Skeleton count={13} style={{padding: '10px', margin:'2px 0px'}} />
                </div>
            )
        }
    }
}

export default withRouter(ProductLeftCategoryMenu)