import { checkoutConstants } from "../Constants/checkout.constants";
import { cardPaymentService } from '../payment.services';
import { redirect } from '../redirecturl';
import { history } from '../history';
import API_BASE_URL from "../../config/api";

export function addBillingInfo(billingFirstName, billingLastName, billingEmail, billingCompany, billingAddress1, billingAddress2, billingCity, billingState, billingZipCode, billingCountry, billingPhone) {
  var billingObj = {
                      'billingFirstName': billingFirstName,
                      'billingLastName': billingLastName, 
                      'billingEmail': billingEmail, 
                      'billingCompany': billingCompany, 
                      'billingAddress1': billingAddress1,
                      'billingAddress2': billingAddress2,
                      'billingCity': billingCity,
                      'billingState': billingState,
                      'billingZipCode': billingZipCode,
                      'billingCountry': billingCountry,
                      'billingPhone': billingPhone
                  }
  
  return dispatch => {
      dispatch(request(billingObj));
  }

  function request(billingObj) { return { type: checkoutConstants.STORE_BILLING_INFO, billingObj } }
}

export function addShippingInfo(isShippingSameAsBilling, shippingFirstName, shippingLastName, shippingEmail, shippingAddress1, shippingAddress2, shippingCity, shippingState, shippingZipCode, shippingCountry, shippingPhone) {
  var shippingObj = {
                      'isShippingSameAsBilling': isShippingSameAsBilling,
                      'shippingFirstName': shippingFirstName,
                      'shippingLastName': shippingLastName, 
                      'shippingEmail': shippingEmail, 
                      'shippingAddress1': shippingAddress1,
                      'shippingAddress2': shippingAddress2,
                      'shippingCity': shippingCity,
                      'shippingState': shippingState,
                      'shippingZipCode': shippingZipCode,
                      'shippingCountry': shippingCountry,
                      'shippingPhone': shippingPhone
                  }
  
  return dispatch => {
      dispatch(request(shippingObj));
  }

  function request(shippingObj) { return { type: checkoutConstants.STORE_SHIPPING_INFO, shippingObj } }
}

export function submitCardPaymentForm(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, pickupInfoObj, cardInfoObj, couponInfoObj, clientIpAddress) {
  //console.log(couponInfoObj);
  let paymentMethod = "online";
  return dispatch => {
    dispatch(request({billingInfoObj}));

    cardPaymentService.cardPaymentMethod(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, pickupInfoObj, cardInfoObj, paymentMethod, couponInfoObj, clientIpAddress)
        .then(
            res => { 
              //console.log(res);
              if(res.code === 200){
                  //var placeorderResponse = {'order_id':res.order_id, 'status':res.status};
                  var placeorderResponse = {
                                            'payment_method': "online", 
                                            'status':res.data.status, 
                                            'order_id':res.data.order_id, 
                                            'order_amount': res.data.order_amount, 
                                            'order_time':res.data.time, 
                                            'key_id':res.data.key_id, 
                                            'type': res.data.type,
                                            'hash':res.data.hash, 
                                            'processor_id':res.data.processor_id, 
                                            'return_url': res.data.return_url
                                          };
                  dispatch(success(placeorderResponse));
                  localStorage.removeItem('cartData');
                  localStorage.removeItem('cartAttributesData');
                  //return redirect("https://www.evisionstore.com/api/checkout/payment.php?order_id="+res.order_id+"&ccnumber="+cardInfoObj.cardNumber+"&ccexp="+cardInfoObj.cardExpMonth+"/"+cardInfoObj.cardExpYear+"&cvv="+cardInfoObj.cardCvv+"&payment_method=online&webview=1");
                  //window.location = "https://www.evisionstore.com/api/checkout/payment.php?order_id="+res.order_id+"&ccnumber="+cardInfoObj.cardNumber+"&ccexp="+cardInfoObj.cardExpMonth+"/"+cardInfoObj.cardExpYear+"&cvv="+cardInfoObj.cardCvv+"&payment_method=online";
              } else if(res.code === 400) {
                  let getResponseMessage = res.message;
                  dispatch(failure(getResponseMessage));
              } else {
                  let error = "Woops! Something went wrong. Please try again later.";
                  dispatch(failure(error));
              }
            },
            error => {
              dispatch(failure(error.toString()));
            }
        );
  };

  function request(cardNumber) { return { type: checkoutConstants.CARD_PAYMENT_REQUEST, cardNumber } }
  function success(placeorderResponse) { return { type: checkoutConstants.CARD_PAYMENT_SUCCESS, placeorderResponse } }
  function failure(error) { return { type: checkoutConstants.CARD_PAYMENT_FAILURE, error } }

}

export function submitBacCardPaymentForm(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, pickupInfoObj, cardInfoObj, couponInfoObj, clientIpAddress) {
  console.log(couponInfoObj);
  let paymentMethod = "online_bac";
  //let productsArr = [];
  //let isGuestCheckoutSubmited = false;

  return dispatch => {
    dispatch(request({billingInfoObj}));

    cardPaymentService.cardPaymentMethod(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, pickupInfoObj, cardInfoObj, paymentMethod, couponInfoObj, clientIpAddress)
        .then(
            res => { 
              //console.log(res);
              if(res.code === 200){
                  var placeorderResponse = {
                                            'payment_method': "online_bac", 
                                            'status':res.data.status, 
                                            'order_id':res.data.order_id, 
                                            'order_amount': res.data.order_amount, 
                                            'order_time':res.data.time, 
                                            'key_id':res.data.key_id, 
                                            'type': res.data.type,
                                            'hash':res.data.hash, 
                                            'processor_id':res.data.processor_id, 
                                            'return_url': res.data.return_url
                                          };
                  dispatch(success(placeorderResponse));
                  localStorage.removeItem('cartData');
                  localStorage.removeItem('cartAttributesData');
                  //return redirect("https://www.evisionstore.com/api/checkout/payment2.php?order_id="+res.order_id+"&ccnumber="+cardInfoObj.cardNumber+"&ccexp="+cardInfoObj.cardExpMonth+"/"+cardInfoObj.cardExpYear+"&cvv="+cardInfoObj.cardCvv+"&payment_method=online_bac&webview=1");
                  //window.location = "https://www.evisionstore.com/api/checkout/payment.php?order_id="+res.order_id+"&ccnumber="+cardInfoObj.cardNumber+"&ccexp="+cardInfoObj.cardExpMonth+"/"+cardInfoObj.cardExpYear+"&cvv="+cardInfoObj.cardCvv+"&payment_method=online";
              } else if(res.code === 400) {
                  let getResponseMessage = res.message;
                  dispatch(failure(getResponseMessage));
              } else {
                  let error = "Woops! Something went wrong. Please try again later.";
                  dispatch(failure(error));
              }
            },
            error => {
              dispatch(failure(error.toString()));
            }
        );
  };

  function request(cardNumber) { return { type: checkoutConstants.CARD_PAYMENT_REQUEST, cardNumber } }
  function success(placeorderResponse) { return { type: checkoutConstants.CARD_PAYMENT_SUCCESS, placeorderResponse } }
  function failure(error) { return { type: checkoutConstants.CARD_PAYMENT_FAILURE, error } }

}

export function submitBankTransferForm(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, pickupInfoObj, couponInfoObj, clientIpAddress) {
  let paymentMethod = "bank_transfer";
  return dispatch => {
    dispatch(request({billingInfoObj}));

    cardPaymentService.banktransferYappyAndClavePaymentMethod(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, pickupInfoObj, paymentMethod, couponInfoObj, clientIpAddress)
        .then(
            res => { 
              if(res.code === 200){
                  var placeorderResponse = {'payment_method': "bank_transfer", 'order_id':res.data.order_id, 'status':res.status};
                  dispatch(success(placeorderResponse));
                  localStorage.removeItem('cartData');
                  localStorage.removeItem('cartAttributesData');
                  //history.push('/success');
              } else if(res.code === 400) {
                  let getResponseMessage = res.message;
                  dispatch(failure(getResponseMessage));
              } else {
                  let error = "Woops! Something went wrong. Please try again later.";
                  dispatch(failure(error));
              }
            },
            error => {
              dispatch(failure(error.toString()));
            }
        );
  };

  function request(cardNumber) { return { type: checkoutConstants.CARD_PAYMENT_REQUEST, cardNumber } }
  function success(placeorderResponse) { return { type: checkoutConstants.CARD_PAYMENT_SUCCESS, placeorderResponse } }
  function failure(error) { return { type: checkoutConstants.CARD_PAYMENT_FAILURE, error } }

}


export function submitYappyForm(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, pickupInfoObj, couponInfoObj, cartAttributesObj, clientIpAddress) {
  let paymentMethod = "yappy";
  return dispatch => {
    dispatch(request({billingInfoObj}));

    cardPaymentService.banktransferYappyAndClavePaymentMethod(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, pickupInfoObj, paymentMethod, couponInfoObj, cartAttributesObj, clientIpAddress)
        .then(
            res => { 
              if(res.code === 200){
                  //console.log(res.yappy_redirect_url)
                  var placeorderResponse = {'payment_method': "yappy", 'order_id':res.data.order_id, 'status':res.data.status, 'yappy_redirect_url':res.data.yappy_redirect_url};
                  dispatch(success(placeorderResponse));
                  localStorage.removeItem('cartData');
                  localStorage.removeItem('cartAttributesData');
                  return redirect(res.data.yappy_redirect_url);
                  //history.push('/success');
              } else if(res.code === 400) {
                  let getResponseMessage = res.message;
                  dispatch(failure(getResponseMessage));
              } else {
                  let error = "Woops! Something went wrong. Please try again later.";
                  dispatch(failure(error));
              }
            },
            error => {
              dispatch(failure(error.toString()));
            }
        );
  };

  function request(cardNumber) { return { type: checkoutConstants.CARD_PAYMENT_REQUEST, cardNumber } }
  function success(placeorderResponse) { return { type: checkoutConstants.CARD_PAYMENT_SUCCESS, placeorderResponse } }
  function failure(error) { return { type: checkoutConstants.CARD_PAYMENT_FAILURE, error } }

}

export function submitClaveForm(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, pickupInfoObj, couponInfoObj, cartAttributesObj, clientIpAddress) {
  let paymentMethod = "online_clave";
  return dispatch => {
    dispatch(request({billingInfoObj}));

    cardPaymentService.banktransferYappyAndClavePaymentMethod(customerId, productsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, pickupInfoObj, paymentMethod, couponInfoObj, cartAttributesObj, clientIpAddress)
        .then(
            res => { 
              //console.log(res);
              if(res.code === 200){
                  var placeorderResponse = {'payment_method': "online_clave", 'order_id':res.data.order_id, 'status':res.status};
                  dispatch(success(placeorderResponse));
                  localStorage.removeItem('cartData');
                  localStorage.removeItem('cartAttributesData');
                  //return redirect("https://www.evisionstore.com/api/checkout/clave-deposit.php?order_id="+res.order_id+"&order_amount="+res.order_amount+"&customer_phone="+res.customer_phone+"&authorization_token="+res.authorization_token);
                  return redirect(`${API_BASE_URL}/clave-deposit?order_id=`+res.data.order_id+"&order_amount="+res.data.order_amount+"&customer_phone="+res.data.customer_phone+"&authorization_token="+res.data.authorization_token);
              } else if(res.code === 400) {
                  let getResponseMessage = res.message;
                  dispatch(failure(getResponseMessage));
              } else {
                  let error = "Woops! Something went wrong. Please try again later.";
                  dispatch(failure(error));
              }
            },
            error => {
              dispatch(failure(error.toString()));
            }
        );
  };

  function request(cardNumber) { return { type: checkoutConstants.CARD_PAYMENT_REQUEST, cardNumber } }
  function success(placeorderResponse) { return { type: checkoutConstants.CARD_PAYMENT_SUCCESS, placeorderResponse } }
  function failure(error) { return { type: checkoutConstants.CARD_PAYMENT_FAILURE, error } }
}

export function allowGuestCheckout() {
  let allowGuestCheckoutMethod = true;
  return dispatch => {
      dispatch(request(allowGuestCheckoutMethod));
      //return redirect('/checkout');
  }
  function request(allowGuestCheckoutMethod) { return { type: checkoutConstants.GUEST_CHECKOUT_METHOD, allowGuestCheckoutMethod } }
}


