import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Pagination from './ProductPagination';
import { addToCart, addToCart2 } from '../../Services/Actions/cart.actions';
import { resetFilter, setBrandOptions, setProductsById } from '../../Services/Actions/filter.actions';
import { Helmet } from 'react-helmet';
import Skeleton from "react-loading-skeleton";
import { redirectTo } from '../Header/redirecturl';

class ProductRightCategoryListComp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isProductError: null,
            isProductsLoaded : false,
            categoryProducts: [],
            subCategoryList: [],
            pageOfItems: [], showUpdateCartMsgModal: false,
            isCartAddError: false, cartErrorMessage: '', addToCartButtonnLoader: "hidden", addToCartButtonnCart: '', addToCartButton: true, addToCartButtonDisabled: false,
            clickedProductId: '', categoryProductName: '', paginationProductModel: []
        }

        this.onChangePage = this.onChangePage.bind(this);
        this.openCartReturnPop = this.openCartReturnPop.bind(this);
        this.closeCartReturnPop = this.closeCartReturnPop.bind(this);
        this.cartUpdatePopupResOpen = this.cartUpdatePopupResOpen.bind(this);
        this.cartUpdatePopupResClose = this.cartUpdatePopupResClose.bind(this);
    }

    openCartReturnPop() {
        this.setState({showCartReturnModal: true});
    }
    
    closeCartReturnPop() {
        this.setState({showCartReturnModal: false});
    }

    cartUpdatePopupResOpen() {
        this.setState({showUpdateCartMsgModal: true});
    }
    
    cartUpdatePopupResClose() {
        this.setState({showUpdateCartMsgModal: false});
    }

    handleOnProductRedirect(productId){
        return redirectTo("/product/"+productId);
    }

    handleOnAddToCart(product_id, product_name, product_image, price, quantity, brand, modelo, category_name) {
        this.setState({addToCartButtonnLoader: '', addToCartButtonnCart: 'hidden', addToCartButton: true, addToCartButtonDisabled: true, cartErrorMessage: '', clickedProductId: product_id})
        let checked_variation_product_id = 0;
        this.props.addToCart(product_id, product_name, product_image, price, quantity, checked_variation_product_id, modelo);

        setTimeout(()=> 
            this.setState({
                isCartAddError: this.props.isCartAddError 
            },() => {
                if(this.state.isCartAddError){
                    this.setState({cartErrorMessage: this.props.cartErrorMessage, addToCartButtonnLoader: 'hidden', addToCartButtonnCart: '', addToCartButton: true, addToCartButtonDisabled: false, clickedProductId: ''});
                    this.cartUpdatePopupResOpen();
                    setTimeout(()=> 
                        this.cartUpdatePopupResClose(),
                    5000);
                } else {
                    this.openCartReturnPop();
                    this.setState({addToCartButtonnLoader: 'hidden', addToCartButtonnCart: '', addToCartButton: false, addToCartButtonDisabled: false, cartErrorMessage: ''});
                
                    window.fbq('track', 'AddToCart', {
                        content_ids: [product_id],
                        content_type: 'product',
                        value: price,
                        currency:'USD'
                    });

                    window.ga("ec:addProduct", {
                        "id": modelo,
                        "name": brand + " " + modelo,
                        "price": price,
                        "brand": brand,
                        "category": category_name,
                        "quantity": 1
                    });
                    window.ga("ec:setAction", "add");
                    window.ga("send", "event", "Product List Page", "click", "addToCart");
                
                    window.gtag('event', 'add_to_cart', { 
                        'send_to': 'UA-521381-2/yuuFCLjX-aUBENbiovUC', 
                        'value': price, 
                        'currency': 'USD',  
                        'event_callback': true 
                    });
                }
            }),
        3000);
    }

    onChangePage(pageOfItems) {
        // update state with new page of items
        this.setState({ 
            pageOfItems: pageOfItems 
        },() => {
            if(this.state.pageOfItems.length !== 0){
                let l = (this.state.pageOfItems.length - 1);
                let s = "";
                this.state.pageOfItems.map((allProductArr, index) => (
                    index === l
                    ? s += "'" + allProductArr.modelo + "'"
                    : s += "'" + allProductArr.modelo + "',"
                ))  
                this.setState({paginationProductModel: s});
            }
        });
    }

    componentDidMount() {
        const apiUrl = 'https://www.evisionstore.com/api/product/list-react2.php';

        let category_id = this.props.match.params.categoryId;
        let brand = this.props.match.params.brand;
        this.props.resetFilter(category_id);
        
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify({"category_id": category_id})
        };

        if(brand){
            requestOptions = {
                method: 'POST',
                body: JSON.stringify({"category_id": category_id, "brand": brand})
            };
        }
        
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result)
                this.setState({
                    categoryProductName: result.category_name,
                    categoryProducts: result.product_list,
                    subCategoryList: result.category_list,
                    isProductsLoaded: true
                });
                //localStorage.setItem('productsData', JSON.stringify(result.product_list));
                this.props.setProductsById(result.product_list);
                //console.log(result.product_list)
                var res = result.product_list;
                let mymap = new Map(); 
  
                let unique = res.filter(el => { 
                    const val = mymap.get(el.brand); 
                    if(val) { 
                        if(el.id < val) { 
                            mymap.delete(el.brand); 
                            mymap.set(el.brand, el.brand); 
                            return true; 
                        } else { 
                            return false; 
                        } 
                    } 
                    mymap.set(el.brand, el.brand); 
                    return true; 
                }); 

                var resArr = [];
                unique.filter(function(item){
                    var i = resArr.findIndex(x => x.brand == item.brand);
                    if(i <= -1){
                        resArr.push({name: item.brand, id: item.brand});
                    }
                    return null;
                });
                this.props.setBrandOptions(resArr);
                //console.log(resArr);
            },
            (error) => {
                this.setState({ isProductError: error });
            }
        )
        
        window.Intercom('update', {
            "hide_default_launcher": true
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.match.params.categoryId !== this.props.match.params.categoryId) {
            this.setState({
                isProductsLoaded: false
            });

            let category_id = this.props.match.params.categoryId;
            this.props.resetFilter(category_id);
            const apiUrl = 'https://www.evisionstore.com/api/product/list-react2.php';
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({"category_id": category_id})
            };
            fetch(apiUrl, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        categoryProductName: result.category_name,
                        categoryProducts: result.product_list,
                        subCategoryList: result.category_list,
                        isProductsLoaded: true
                    });
                    //localStorage.setItem('productsData', JSON.stringify(result.product_list));
                    this.props.setProductsById(result.product_list);
                    
                    //Added this code for to get unique category name from array
                    var res = result.product_list;
                    let mymap = new Map(); 
    
                    let unique = res.filter(el => { 
                        const val = mymap.get(el.brand); 
                        if(val) { 
                            if(el.id < val) { 
                                mymap.delete(el.brand); 
                                mymap.set(el.brand, el.brand); 
                                return true; 
                            } else { 
                                return false; 
                            } 
                        } 
                        mymap.set(el.brand, el.brand); 
                        return true; 
                    }); 

                    var resArr = [];
                    unique.filter(function(item){
                        var i = resArr.findIndex(x => x.brand == item.brand);
                        if(i <= -1){
                            resArr.push({name: item.brand, id: item.brand});
                        }
                        return null;
                    });
                    this.props.setBrandOptions(resArr);
                    //Added this code for to get unique category name from array
                },
                (error) => {
                    this.setState({ isProductError: error });
                }
            )
        }
    }

    render(){
        const { isProductsLoaded, categoryProducts, categoryProductName, pageOfItems, subCategoryList } = this.state;
        const { filteredCategoryProducts, isFilterProductsLoaded } = this.props;
        //const { match, location, history, cartData } = this.props;
        //console.log(this.state.paginationProductModel);

        if(isProductsLoaded && isFilterProductsLoaded===false){
            if (categoryProducts && categoryProducts.length > 0) {
                
                return(
                    <>
                    <Helmet>
                        {/* Criteo Category / Listing dataLayer */}
                        <script>
                        {`
                            var dataLayer = dataLayer || [];
                            dataLayer.push({
                                event: 'crto_listingpage',
                                crto: {
                                    email: "${this.props.loggedInUserEmail}",
                                    products: [${this.state.paginationProductModel}]
                                }
                            });
                        `}
                        </script>
                        {/* END Criteo Category / Listing dataLayer */}

                        {/* Criteo GTM Tag */}
                        <script type="text/javascript" src="//static.criteo.net/js/ld/ld.js" async="true"></script>
                        <script>
                        {`
                            window.criteo_q = window.criteo_q || [];
                            window.criteo_q.push(
                            { event: "setAccount", account: 65991 },
                            { event: "setEmail", email: "${this.props.loggedInUserEmail}" },
                            { event: "setSiteType", type: "d" },
                            { event: "viewList", item: [${this.state.paginationProductModel}] }
                            );
                        `}
                        </script>
                        {/* End Criteo GTM Tag */}
                    </Helmet>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
                        {(() => {
                        if (subCategoryList.length > 0){
                            return ( 
                            <div className="row">
                                <div className="sub-cat-area">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="product-title"><h2>{categoryProductName}</h2></div>
                                    </div>
                                    {subCategoryList.map((subcat, index) => (
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={index}>
                                        <div className="sub-product-link-item">
                                        <Link to={`/product-category/${subcat.category_id}`}>
                                            <figure>
                                                <img src={subcat.category_image} alt="" style={{height: '92px'}} />
                                            </figure>
                                            <div className="heading-wrap">
                                                <h3>{subcat.category_name}</h3>
                                            </div>
                                        </Link>
                                        </div>
                                    </div>
                                    ))}		
                                </div>	
                            </div>
                            )
                        } else {
                            return null;
                        }
                        })()}
                        <div className="row">
                        
                        {pageOfItems.map((localState, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={localState.product_id}>
                                <Helmet>
                                <script>
                                {`
                                    ga("ec:addImpression", {
                                    "id": "${localState.modelo}",
                                    "name": "${localState.brand} ${localState.modelo}",
                                    "price": "${localState.price}",
                                    "brand": "${localState.brand}",
                                    "category": "${localState.category_name}",
                                    "position": ${(index + 1)}
                                    });
                                `}
                                </script>
                                </Helmet>
                                <div className="deal-link-item">
                                    {/* <Link to={`/product/${localState.product_id}`}> */}
                                        <figure>
                                        <a href="javascript:void(0)" className="wishlist-link">
                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/wishlist-icon.svg`} width="20" height="18" alt="wishlist" />
                                        </a>
                                        {(() => {
                                            let modelNumber = localState.modelo;
                                            let checkSlash = "/";
                                            let setModelNumber;
                                            if(modelNumber.indexOf(checkSlash) !== -1){
                                                setModelNumber = localState.modeloch;
                                            }else{
                                                setModelNumber = localState.modelo;
                                            }

                                            if (localState.brand === 'lg' || localState.brand === 'samsung'){
                                                return (
                                                <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                    <img src={localState.product_image} alt="" />
                                                </Link> 
                                                )
                                            } else {
                                                return (
                                                    <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                        <img src={localState.product_image} alt="" />
                                                    </Link>
                                                )
                                            }
                                        })()}
                                        </figure>
                                        <div className="heading-wrap">
                                            <h2>
                                                {(() => {
                                                    let modelNumber = localState.modelo;
                                                    let checkSlash = "/";
                                                    let setModelNumber;
                                                    if(modelNumber.indexOf(checkSlash) !== -1){
                                                        setModelNumber = localState.modeloch;
                                                    }else{
                                                        setModelNumber = localState.modelo;
                                                    }

                                                    if (localState.brand === 'lg' || localState.brand === 'samsung'){
                                                        return (
                                                        <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                            {localState.product_name} 
                                                        </Link>
                                                        // <Link to={`/product/${localState.product_id}?brand=${localState.brand}`}>
                                                        //     {localState.product_name} 
                                                        // </Link> 
                                                        )
                                                    } else {
                                                        return (
                                                            <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                                {localState.product_name} 
                                                            </Link>
                                                        )
                                                    }
                                                })()}
                                            </h2>
                                            <div className="review">
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/star.svg`} width="20" height="19" title="star" alt="star" />
                                                <span>5.0 | 14 opiniones</span>
                                            </div>
                                            <div className="pricecart">
                                            {(() => {
                                                if (localState.price === '0.00'){
                                                    return (<></>)
                                                } else {
                                                    return (
                                                        <div className="price">
                                                            {localState.regular_price ? (
                                                                <span style={{textDecoration:'line-through', fontWeight:'400', fontSize:'15px', marginRight:'10px', color:'#4d4a4a'}}>
                                                                    $ {localState.regular_price}
                                                                </span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            $ {localState.price}
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            
                                            {/* <h3>{localState.short_description}</h3> */}
                                            {(() => {
                                            if (localState.allow_purchase === '1'){
                                                if(localState.is_avl_attributes){
                                                    
                                                    let modelNumber = localState.modelo;
                                                    let checkSlash = "/";
                                                    let setModelNumber;
                                                    if(modelNumber.indexOf(checkSlash) !== -1){
                                                        setModelNumber = localState.modeloch;
                                                    }else{
                                                        setModelNumber = localState.modelo;
                                                    }

                                                    

                                                    return (
                                                        <div>
                                                            <p style={{borderBottom:'0px'}}>
                                                                {localState.avl_attributes.map((attribute, index) => (
                                                                    <Link key={index} to="#" style={{backgroundColor:attribute.attribute_term_code, padding:'0px 10px', borderRadius:'20px', margin:'0px 3px'}} title={attribute.attribute_term_name} onClick={() => this.handleOnProductRedirect(setModelNumber)}></Link>
                                                                ))}
                                                            </p>

                                                        <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                            Ver el producto <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                                        </Link>
                                                        </div>
                                                        )
                                                }else{
                                                    if(this.state.addToCartButton){
                                                        return (
                                                        <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1", localState.brand, localState.modelo, localState.category_name)} disabled={this.state.addToCartButtonDisabled}>
                                                    
                                                            {(() => {
                                                                if (this.state.clickedProductId == localState.product_id && this.state.addToCartButtonnLoader === ""){
                                                                    return (
                                                                        <>
                                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                        </>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <>
                                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                        </>
                                                                    )
                                                                }
                                                            })()}
                                                        </button>
                                                        )
                                                    } else if(!this.state.addToCartButton && this.state.clickedProductId === localState.product_id){
                                                        return (
                                                        <Link to="/cart">View Cart <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                        )
                                                    } else if(!this.state.addToCartButton && this.state.clickedProductId != localState.product_id){
                                                        return (
                                                            <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1")} >
                                                                <i className="fa fa-shopping-cart" ></i> Agregar al carrito 
                                                            </button>
                                                            )
                                                    } else {
                                                        return null;
                                                    }
                                                }
                                            }
                                            })()}
                                            </div>
                                        </div>
                                    {/* </Link> */}
                                </div>	
                            </div>
                            ))}

                            <Helmet>
                                <script>
                                {`
                                    ga('send', 'pageview');
                                `}
                                </script>
                            </Helmet>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Pagination items={categoryProducts} onChangePage={this.onChangePage} />
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.showUpdateCartMsgModal} onHide={this.cartUpdatePopupResClose} animation={true}>                          
                        <Modal.Header closeButton>
                            <Modal.Title>Oops!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body><p style={{textAlign:'center'}}>{this.state.cartErrorMessage}</p></Modal.Body>     
                    </Modal>

                    <Modal show={this.state.showCartReturnModal} onHide={this.closeCartReturnPop} animation={true}>                          
                        <Modal.Header>
                            <button type="button" className="close" onClick={this.closeCartReturnPop}>&times;</button>
                            <Modal.Title>
                                <i className="fa fa-check" aria-hidden="true"></i> El producto ha sido agregado al carrito.
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <p style={{marginTop: '17px', textAlign: 'center'}}>
                        <Link to="/cart" className="btn btn-primary btn-lg">
                            &nbsp;&nbsp;Proceder a Pagar <i className="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;&nbsp;
                        </Link>
                        <button className="btn btn-success btn-lg" onClick={this.closeCartReturnPop} style={{margin:"0 0 0 30px"}}>
                            &nbsp;&nbsp;<i className="fa fa-shopping-basket" aria-hidden="true"></i> Seguir Comprando&nbsp;&nbsp;
                        </button>
                        </p>
                        </Modal.Body>     
                    </Modal>
                    </>
                )

            }else{

                return(
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >	
                            <h3>No Product Found.</h3>
                            </div>
                        </div>
                    </div>
                )

            }
        }else if(isProductsLoaded && isFilterProductsLoaded){
            if (filteredCategoryProducts && filteredCategoryProducts.length > 0) {
                
                return(
                    <>
                    <Helmet>
                        {/* Criteo Category / Listing dataLayer */}
                        <script>
                        {`
                            var dataLayer = dataLayer || [];
                            dataLayer.push({
                                event: 'crto_listingpage',
                                crto: {
                                    email: "${this.props.loggedInUserEmail}",
                                    products: [${this.state.paginationProductModel}]
                                }
                            });
                        `}
                        </script>
                        {/* END Criteo Category / Listing dataLayer */}

                        {/* Criteo GTM Tag */}
                        <script type="text/javascript" src="//static.criteo.net/js/ld/ld.js" async="true"></script>
                        <script>
                        {`
                            window.criteo_q = window.criteo_q || [];
                            window.criteo_q.push(
                            { event: "setAccount", account: 65991 },
                            { event: "setEmail", email: "${this.props.loggedInUserEmail}" },
                            { event: "setSiteType", type: "d" },
                            { event: "viewList", item: [${this.state.paginationProductModel}] }
                            );
                        `}
                        </script>
                        {/* End Criteo GTM Tag */}
                    </Helmet>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
                        <div className="row">
                        {pageOfItems.map((localState, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={localState.product_id}>
                                <div className="deal-link-item">
                                    {/* <Link to={`/product/${localState.product_id}`}> */}
                                        <figure>
                                            <a href="javascript:void(0)" className="wishlist-link">
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/wishlist-icon.svg`} width="20" height="18" alt="wishlist" />
                                            </a>
                                            {(() => {
                                                let modelNumber = localState.modelo;
                                                let checkSlash = "/";
                                                let setModelNumber;
                                                if(modelNumber.indexOf(checkSlash) !== -1){
                                                    setModelNumber = localState.modeloch;
                                                }else{
                                                    setModelNumber = localState.modelo;
                                                }
                                        
                                                return (
                                                <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                    <img src={localState.product_image} alt="" />
                                                </Link> 
                                                )
                                            })()}
                                        </figure>
                                        <div className="heading-wrap">
                                            <h2>
                                                {(() => {
                                                    let modelNumber = localState.modelo;
                                                    let checkSlash = '/';
                                                    let setModelNumber;
                                                    if(modelNumber.indexOf(checkSlash) !== -1){
                                                        setModelNumber = localState.modeloch;
                                                    }else{
                                                        setModelNumber = localState.modelo;
                                                    }
                                            
                                                    return (
                                                    <Link to="#" onClick={() => this.handleOnProductRedirect(setModelNumber)}>
                                                        {localState.product_name} {}
                                                    </Link> 
                                                    )
                                                })()}
                                            </h2>
                                            <div className="review">
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/star.svg`} width="20" height="19" title="star" alt="star" />
                                                <span>5.0 | 14 opiniones</span>
                                            </div>
                                            <div className="pricecart">
                                            {(() => {
                                                if (localState.price === '0.00'){
                                                    return (<></>)
                                                } else {
                                                    return (
                                                        <div className="price">
                                                            {localState.regular_price ? (
                                                                <span style={{textDecoration:'line-through', fontWeight:'400', fontSize:'15px', marginRight:'10px', color:'#4d4a4a'}}>
                                                                    $ {localState.regular_price}
                                                                </span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            $ {localState.price}
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            
                                            {/* <h3>{localState.short_description}</h3> */}
                                            {(() => {
                                            if (localState.allow_purchase === '1'){
                                                if(this.state.addToCartButton){
                                                    return (
                                                    <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1", localState.brand, localState.modelo, localState.category_name)} disabled={this.state.addToCartButtonDisabled}>
                                                 
                                                        {(() => {
                                                            if (this.state.clickedProductId == localState.product_id && this.state.addToCartButtonnLoader === ""){
                                                                return (
                                                                    <>
                                                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                    </>
                                                                )
                                                            } else {
                                                                return (
                                                                    <>
                                                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`} width="20" height="16" alt="Cart" />
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                    </button>
                                                    )
                                                } else if(!this.state.addToCartButton && this.state.clickedProductId === localState.product_id){
                                                    return (
                                                    <Link to="/cart">View Cart <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                    )
                                                } else if(!this.state.addToCartButton && this.state.clickedProductId != localState.product_id){
                                                    return (
                                                        <button onClick={() => this.handleOnAddToCart(localState.product_id, localState.product_name, localState.product_image, localState.price, "1")} >
                                                            <i className="fa fa-shopping-cart" ></i> Agregar al carrito 
                                                        </button>
                                                        )
                                                } else {
                                                    return null;
                                                }
                                            }
                                            })()}
                                            </div>
                                        </div>
                                    {/* </Link> */}
                                </div>	
                            </div>
                            ))}

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Pagination items={filteredCategoryProducts} onChangePage={this.onChangePage} />
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.showUpdateCartMsgModal} onHide={this.cartUpdatePopupResClose} animation={true}>                          
                        <Modal.Header closeButton>
                            <Modal.Title>Oops!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body><p style={{textAlign:'center'}}>{this.state.cartErrorMessage}</p></Modal.Body>     
                    </Modal>
                    </>
                )

            }else{

                return(
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >	
                            <h3>No Product Found.</h3>
                            </div>
                        </div>
                    </div>
                )

            }

        }else{
           
            return(
                <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
                    <div className="row">
                        {Array(6).fill().map((item, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={index}>
                                <div className="deal-link-item" key={index}>
                                    <Skeleton height={200} width={`85%`} style={{marginBottom:'20px'}} />
                                    <Skeleton height={30} width={90} style={{marginBottom:'7px'}} />
                                    <Skeleton width={180} style={{marginBottom:'7px'}} />
                                    <Skeleton width={200} style={{marginBottom:'7px'}} />
                                    <Skeleton height={35} width={225} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )

        }
    }
    
}

function mapStateToProps(state) {
    //console.log(state.cart.cartData);
    localStorage.setItem("cartData", JSON.stringify(state.cart.cartData))
    return { 
        isLoggedIn: state.user.isLoggedIn,
        loggedInUserEmail: (typeof(state.user.user) === 'undefined') ? "" :  state.user.user.email,
        isError: state.user.isError,
        errorMessage: state.user.errorMessage,
        cartData: state.cart.cartData,
        filteredCategoryProducts: state.filter.filteredProducts,
        isFilterProductsLoaded: state.filter.isFilterProductsLoaded,
        isCartAddError: state.cart.isCartAddError,
        cartErrorMessage: state.cart.cartErrorMessage,
    }
}

const MapDispachToProps = dispatch => ({
    addToCart: () => dispatch(addToCart())
})

const actionCreators = {
    addToCart:addToCart2,
    resetFilter: resetFilter,
    setBrandOptions: setBrandOptions,
    setProductsById: setProductsById
};

const ProductRightCategoryList = connect(
    mapStateToProps,
    actionCreators
)(ProductRightCategoryListComp)

export default withRouter(ProductRightCategoryList);