import { cartConstants } from "../Constants/cart.constants";
import { userService } from '../user.services';

export const addToCart=(prp)=>(dispatch: any)=>{
    dispatch({
        type: cartConstants.ADD_TO_CART
        //data: logoutsuccss
    })
}

export function addToCart2(prodId, prodName, prodImage, prodPrice, prodQuantity, prodSelectedVariationId = 0, prodModelo, isAllowedBacCredomatic,  prodSelectedAttribte = false) {
    //var prodObj = {[prodId]:{'prodName':prodName, 'prodImage':prodImage, 'prodPrice':prodPrice, 'prodQuantity':1}}
    var prodObj = {'prodId':prodId, 'prodModelo':prodModelo, 'prodName':prodName, 'prodImage':prodImage, 'prodPrice':prodPrice, 'prodQuantity':prodQuantity, 'isAllowedBacCredomatic': isAllowedBacCredomatic, 'prodSelectedVariationId':prodSelectedVariationId}
    //console.log("----"+prodObj);
    return dispatch => {
        dispatch(request(prodObj));

        let prodAttribtes = "";
        if(prodSelectedAttribte) {
            prodAttribtes = prodSelectedAttribte;
        }

        let prodVariationId = "";
        if(prodSelectedVariationId) {
            prodVariationId = prodSelectedVariationId;
        }else{
            prodVariationId = 0;
        }

        const userArrData = localStorage.getItem('user');
        let userArr = false;
        let userArrLen = 0;

        if(userArrData && userArrData !== 'undefined'){
            userArr = JSON.parse(localStorage.getItem('user'));
            userArrLen = Object.keys(userArr).length;
        }

        if (userArrLen && userArrLen > 0) {
            let customerId = userArr.customer_id;
            
            if(customerId && customerId >= 0){
                userService.addToCartByCustomerId(prodId, prodQuantity, customerId, prodAttribtes, prodVariationId)
                    .then(
                        res => { 
                            if(res.code === 200){
                                const successMsg = res.data.success;
                                dispatch(success(successMsg));
                            } else if(res.code === 404) {
                                let getResponseMessage = res.error;
                                dispatch(failure(getResponseMessage));
                                var prodObj = {'prodId':prodId,'prodName':prodName, 'prodImage':prodImage, 'prodPrice':prodPrice, 'prodQuantity':res.qty, 'prodSelectedVariationId':prodSelectedVariationId}
                                dispatch(updaterequest(prodObj));
                            } else {
                                let error = "Woops! Something went wrong. Please try again later.";
                                dispatch(failure(error));
                            }
                        }
                    );
            }
        }/*  else {
            dispatch(request(prodObj));
        } */
    }
    function request(prodObj) { return { type: cartConstants.ADD_TO_CART, prodObj } }
    function updaterequest(prodObj) { return { type: cartConstants.UPDATE_TO_CART, prodObj } }
    function success(successMsg) { return { type: cartConstants.ADD_TO_DATABASE_CART, successMsg } }
    function failure(error) { return { type: cartConstants.ADD_TO_DATABASE_CART_FAILURE, error } }
}

export function updateCart(prodId, prodName, prodImage, prodPrice, prodQuantity, prodSelectedVariationId = 0) {
    var prodObj = {'prodId':prodId,'prodName':prodName, 'prodImage':prodImage, 'prodPrice':prodPrice, 'prodQuantity':prodQuantity, 'prodSelectedVariationId':prodSelectedVariationId}
 
    return dispatch => {
        dispatch(request(prodObj));

        const userArrData = localStorage.getItem('user');
        let userArr = false;
        let userArrLen = 0;

        if(userArrData && userArrData !== 'undefined'){
            userArr = JSON.parse(localStorage.getItem('user'));
            userArrLen = Object.keys(userArr).length;
        }

        let prodVariationId = "";
        if(prodSelectedVariationId) {
            prodVariationId = prodSelectedVariationId;
        }else{
            prodVariationId = 0;
        }

        if (userArrLen && userArrLen > 0) {
            let customerId = userArr.customer_id;
            
            if(customerId && customerId >= 0){
                userService.updateCartByCustomerId(prodId, prodQuantity, customerId, prodVariationId)
                    .then(
                        res => { 
                            if(res.code === 200){
                                const successMsg = res.success;
                                dispatch(success(successMsg));
                            } else if(res.code === 400) {
                                let getResponseMessage = res.message;
                                dispatch(failure(getResponseMessage));
                                var prodObj = {'prodId':prodId,'prodName':prodName, 'prodImage':prodImage, 'prodPrice':prodPrice, 'prodQuantity':res.qty, 'prodSelectedVariationId':prodSelectedVariationId}
                                dispatch(request(prodObj));
                            } else {
                                let error = "Woops! Something went wrong. Please try again later.";
                                dispatch(failure(error));
                            }
                        },
                        error => {
                          dispatch(failure(error.toString()));
                        }
                    );
            }
        }
    }
    function request(prodObj) { return { type: cartConstants.UPDATE_TO_CART, prodObj } }
    function success(successMsg) { return { type: cartConstants.UPDATE_DATABASE_CART, successMsg } }
    function failure(error) { return { type: cartConstants.UPDATE_DATABASE_CART_FAILURE, error } }
}

export function removeCart(prodId, index, prodVariationId) {
    var prodObj = {'prodId':prodId, 'prodIndex':index, 'prodVariationId': prodVariationId}

    return dispatch => {
        dispatch(request(prodObj));

        const userArrData = localStorage.getItem('user');
        let userArr = false;
        let userArrLen = 0;

        if(userArrData && userArrData !== 'undefined'){
            userArr = JSON.parse(localStorage.getItem('user'));
            userArrLen = Object.keys(userArr).length;
        }

        if (userArrLen && userArrLen > 0) {
            let customerId = userArr.customer_id;
            
            if(customerId && customerId >= 0){
                userService.removeCartByCustomerId(prodId, customerId, prodVariationId)
                    .then(
                        res => { 
                            if(res.code === 200){
                                const successMsg = res.success;
                                dispatch(success(successMsg));
                            }
                        }
                    );
            }
        }
    }
    function request(prodObj) { return { type: cartConstants.REMOVE_FROM_CART, prodObj } }
    function success(successMsg) { return { type: cartConstants.REMOVE_DATABASE_CART, successMsg } }
}

export function addAttributeToCart(prodId, prodVariationId, attributesArr) {
    var prodAttrObj = {'prodId': prodId, 'prodVariationId': prodVariationId, 'prodAttribute': attributesArr}
 
    return dispatch => {
        dispatch(request(prodAttrObj));
    }

    function request(prodAttrObj) { return { type: cartConstants.ADD_ATTRIBUTE_TO_CART, prodAttrObj } }
}