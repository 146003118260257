import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import ProductLeftCategoryMenu from "./ProductLeftCategoryMenu";
import ProductRightCategoryList from "./ProductRightCategoryList";
import { productsListByBrand, productsListByAllowPurchase, productsListByPriceRange, priceFilterByType, resetFilterByPriceRange } from "../../Services/Actions/filter.actions";
import API_BASE_URL from "../../config/api";

class ProductFilterSectionComp extends Component {
  constructor(props) {
    super(props);
    console.log("Filter: ", props);
    this.state = {
      //brandOptions: [{name: 'Canon', id: 'canon'},{name: 'Dji', id: 'dji'},{name: 'Gopro', id: 'gopro'},{name: 'Nikon', id: 'nikon'}],
      stockOptions: [{ name: "In Stock", id: 1 }],
      fromRange: "",
      toRange: "",
      selectedBrands: [],
      isSelectedStockProduct: "",
      mob_cat_products: [],
      selectedParentCatId: false,
      catProductsLoaded: false,
      showNavModal: false,
      showFormModal: "hidden",
    };
    this.onBrandSelect = this.onBrandSelect.bind(this);
    this.onBrandRemove = this.onBrandRemove.bind(this);
    this.onStockSelect = this.onStockSelect.bind(this);
    this.OnPriceFilterChange = this.OnPriceFilterChange.bind(this);
    this.handleOnPriceChange = this.handleOnPriceChange.bind(this);
    this.onBrandSearch = this.onBrandSearch.bind(this);
    this.onAllowPurchaseProductSearch = this.onAllowPurchaseProductSearch.bind(this);
    this.onPriceRangeProductSearch = this.onPriceRangeProductSearch.bind(this);
    this.onPriceRangeProductReset = this.onPriceRangeProductReset.bind(this);
    //this.multiselectRef = React.createRef();
    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.openFrom = this.openFrom.bind(this);
    this.closeFrom = this.closeFrom.bind(this);
  }

  async componentDidMount() {
    const apiUrl = `${API_BASE_URL}/category`;

    try {
        let response = await fetch(apiUrl);
        let result = await response.json();
        this.setState({
            mob_cat_products: result.data.menu,
            selectedParentCatId: result.data.selected_parent_category_id,
            catProductsLoaded: true,
        });
    } catch (error) {
        this.setState({ error });
    }
}

  onBrandSelect(selectedList, selectedItem) {
    let brandsArr = {};
    brandsArr["brand"] = selectedItem.id;
    this.setState({
      selectedBrands: [...this.state.selectedBrands, brandsArr],
    });
  }

  onBrandRemove(selectedList, removedItem) {
    const updatedBrands = this.state.selectedBrands.filter((task) => task.brand !== removedItem.id);

    this.setState({
      selectedBrands: updatedBrands,
    });
  }

  onStockSelect(selectedList, selectedItem) {
    this.setState({
      isSelectedStockProduct: selectedItem.id,
    });
  }

  handleOnPriceChange(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  onBrandSearch(e) {
    e.preventDefault();
    const selectedBrandIds = this.state.selectedBrands;
    this.props.brandFilter(selectedBrandIds);
  }

  onAllowPurchaseProductSearch(e) {
    e.preventDefault();
    const stockProductId = this.state.isSelectedStockProduct;
    this.props.allowPurchaseFilter(stockProductId);
  }

  handlePriceValidation() {
    let fromRange = this.state.fromRange;
    let toRange = this.state.toRange;
    let isPriceValid = true;

    if (!fromRange) {
      isPriceValid = false;
    }

    if (!toRange) {
      isPriceValid = false;
    }

    return isPriceValid;
  }

  onPriceRangeProductSearch(e) {
    e.preventDefault();

    if (this.handlePriceValidation()) {
      const fromRange = this.state.fromRange;
      const toRange = this.state.toRange;
      this.props.priceRangeFilter(fromRange, toRange);
    }
  }

  onPriceRangeProductReset(e) {
    e.preventDefault();
    const categorySlug = window.location.pathname.split('/').pop();
    this.setState({
      fromRange: "",
      toRange: "",
    });
    this.props.priceRangeFilterReset(categorySlug);
  }

  OnPriceFilterChange(e) {
    if (e.target.value !== "") {
      const priceFilterType = e.target.value;
      this.props.priceFilterByType(priceFilterType);
    }
  }

  openNav() {
    this.setState({ showNavModal: true });
  }

  closeNav() {
    this.setState({ showNavModal: false });
  }

  openFrom() {
    this.setState({ showFormModal: "" });
  }

  closeFrom() {
    this.setState({ showFormModal: "hidden" });
  }

  toggleSelectDrop = () => {
    this.setState((prevState) => ({ selectVisibility: !prevState.selectVisibility }));
  };
  togglePriceDrop = () => {
    this.setState((prevState) => ({ priceVisibility: !prevState.priceVisibility }));
  };
  toggleSortDrop = () => {
    this.setState((prevState) => ({ sortVisibility: !prevState.sortVisibility }));
  };

  render() {
    const { fromRange, toRange, mob_cat_products, showNavModal, showFormModal } = this.state;
    const { dropDownBrandFilterData } = this.props;
    const { selectVisibility } = this.state;
    const { priceVisibility } = this.state;
    const { sortVisibility } = this.state;

    const closeNavPop = {
      width: "0px",
    };

    const openNavPop = {
      width: "250px",
    };

    return (
      <section className="filter">
        <div className="container">
          <div className="row">
            {/* Mobile menu */}
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 mobile">
              <span style={{ fontSize: "34px", cursor: "pointer", margin: "8px 0 0 0", float: "left" }} onClick={this.openNav}>
                ☰
              </span>
              <div id="mySidenav" className="sidenav" style={showNavModal ? openNavPop : closeNavPop}>
                <Link to="#" className="closebtn" onClick={this.closeNav}>
                  ×
                </Link>
                <nav className="navigation">
                  <h2>DEPARTAMENTOS</h2>
                  <ul className="mainmenu">
                  {mob_cat_products && mob_cat_products.length > 0 ? (
                mob_cat_products.map((mob_cat_product) => (
                    <li 
                      key={mob_cat_product.id}
                      className={mob_cat_product.sub && mob_cat_product.sub.length ? "dropdown-list-menu" : ""}>
                        <Link to={`/categorias/${mob_cat_product.slug}`}>
                            {mob_cat_product.name}
                        </Link>
                        {mob_cat_product.sub && mob_cat_product.sub.length > 0 && (
                            <ul className="submenu">
                                {mob_cat_product.sub.map((submenu) => (
                                    <li key={submenu.id}>
                                        <Link to={`/categorias/${mob_cat_product.slug}/${submenu.slug}`}>
                                            {submenu.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))
            ) : (
                <p>No categories available</p>
            )}
                  </ul>
                </nav>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 mobile">
              <div className="filter-icon open-button">
                <img src={`${process.env.PUBLIC_URL}/images/filter-icon.jpg`} alt="filter" onClick={this.openFrom} />
              </div>
              <div className={`form-popup ${showFormModal}`} id="myForm">
                <Link to="#" className="closebtn" onClick={this.closeFrom}>
                  ×
                </Link>
                <form action="/action_page.php" className="form-container">
                  <div className="fields">
                    <div className="lebel">Seleccionar</div>
                    <Multiselect
                      options={dropDownBrandFilterData}
                      showCheckbox={true} // Options to display in the dropdown
                      placeholder="Seleccionar Marca"
                      onSelect={this.onBrandSelect} // Function will trigger on select event
                      onRemove={this.onBrandRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      //ref={this.multiselectRef}
                    />
                    <input type="submit" value="Filtrar Por Marca" onClick={this.onBrandSearch} />

                    <Multiselect
                      options={this.state.stockOptions}
                      showCheckbox={true} // Options to display in the dropdown
                      placeholder="Seleccionar en-stock"
                      onSelect={this.onStockSelect} // Function will trigger on select event
                      //onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                    />
                    <input type="submit" value="Filtrar Productos" onClick={this.onAllowPurchaseProductSearch} />
                  </div>
                  <div className="fields">
                    <div className="lebel">Seleccionar Precio</div>
                    <div className="form-groups">
                      <span>$ </span>
                      <input
                        type="text"
                        name="fromRange"
                        value={fromRange}
                        onChange={this.handleOnPriceChange}
                        //onkeypress="return !isNaN(event.key)"
                        maxLength="6"
                        placeholder="Desde"
                      />
                    </div>
                    <div className="form-groups">
                      <span>$ </span>

                      <input
                        type="text"
                        name="toRange"
                        value={toRange}
                        onChange={this.handleOnPriceChange}
                        //onkeypress="return !isNaN(event.key)"
                        maxLength="6"
                        placeholder="A"
                      />
                    </div>
                    <input type="submit" value="Filtrar Por Precio" onClick={this.onPriceRangeProductSearch} />
                    <input type="submit" value="Reset" onClick={this.onPriceRangeProductReset} />
                  </div>
                  <div className="fields1">
                    <div className="lebel">Ordenar Por</div>
                    <select onChange={this.OnPriceFilterChange}>
                      <option value="">Seleccionar Precio Filtrar</option>
                      <option value="lth">Price: Low to High</option>
                      <option value="htl">Price: High to Low</option>
                    </select>
                  </div>
                </form>
              </div>
              <div id="myFormdiv" className={`${showFormModal}`} />
            </div>
            {/* Mobile menu */}
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 desktop catagory-left-panel">
              <ProductLeftCategoryMenu />
              <div>
                <div className="fields">
                  <div className={`lebel sidebar-toggle-title-wrapper ${selectVisibility ? "dropdown-expanded" : "dropdown-collapsed"}`}>
                    <button type="button" className="sidebar-toggle-title" onClick={this.toggleSelectDrop}>
                      Seleccionar
                      {selectVisibility ? (
                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/dropdown-arrow-up.svg`} alt="arrow up" className="toggle-image" />
                      ) : (
                        <img
                          src={`${process.env.PUBLIC_URL}/images/new-images/icon/dropdown-arrow-down.svg`}
                          alt="arrow down"
                          className="toggle-image"
                        />
                      )}
                    </button>
                  </div>
                  <div className={`${selectVisibility ? "hidden" : "visible"}`}>
                    <Multiselect
                      options={dropDownBrandFilterData}
                      showCheckbox={true} // Options to display in the dropdown
                      placeholder="Seleccionar Marca"
                      onSelect={this.onBrandSelect} // Function will trigger on select event
                      onRemove={this.onBrandRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      //ref={this.multiselectRef}
                    />
                    <input type="submit" value="Filtrar Por Marca" onClick={this.onBrandSearch} />

                    <Multiselect
                      options={this.state.stockOptions}
                      showCheckbox={true} // Options to display in the dropdown
                      placeholder="Seleccionar en-stock"
                      onSelect={this.onStockSelect} // Function will trigger on select event
                      //onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                    />
                    <input type="submit" value="Filtrar Productos" onClick={this.onAllowPurchaseProductSearch} />
                  </div>
                </div>

                <div className="fields">
                  <div className={`lebel sidebar-toggle-title-wrapper ${priceVisibility ? "dropdown-expanded" : "dropdown-collapsed"}`}>
                    <button type="button" className="sidebar-toggle-title" onClick={this.togglePriceDrop}>
                      Seleccionar Precio
                      {priceVisibility ? (
                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/dropdown-arrow-up.svg`} alt="arrow up" className="toggle-image" />
                      ) : (
                        <img
                          src={`${process.env.PUBLIC_URL}/images/new-images/icon/dropdown-arrow-down.svg`}
                          alt="arrow down"
                          className="toggle-image"
                        />
                      )}
                    </button>
                  </div>
                  <div className={`${priceVisibility ? "hidden" : "visible"}`}>
                    <div className="form-groups">
                      <span>$ </span>
                      <input
                        type="text"
                        name="fromRange"
                        value={fromRange}
                        onChange={this.handleOnPriceChange}
                        //onkeypress="return !isNaN(event.key)"
                        maxLength="6"
                        placeholder="Desde"
                      />
                    </div>
                    <div className="form-groups">
                      <span>$ </span>
                      <input
                        type="text"
                        name="toRange"
                        value={toRange}
                        onChange={this.handleOnPriceChange}
                        //onkeypress="return !isNaN(event.key)"
                        maxLength="6"
                        placeholder="A"
                      />
                    </div>
                    <input type="submit" value="Filtrar Por Precio" onClick={this.onPriceRangeProductSearch} />
                    <input type="submit" value="Reset" onClick={this.onPriceRangeProductReset} />
                  </div>
                </div>

                <div className="fields1">
                  <div className={`lebel sidebar-toggle-title-wrapper ${priceVisibility ? "dropdown-expanded" : "dropdown-collapsed"}`}>
                    <button type="button" className="sidebar-toggle-title" onClick={this.toggleSortDrop}>
                      Ordenar Por
                      {sortVisibility ? (
                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/dropdown-arrow-up.svg`} alt="arrow up" className="toggle-image" />
                      ) : (
                        <img
                          src={`${process.env.PUBLIC_URL}/images/new-images/icon/dropdown-arrow-down.svg`}
                          alt="arrow down"
                          className="toggle-image"
                        />
                      )}
                    </button>
                  </div>
                  <div className={`${sortVisibility ? "hidden" : "visible"}`}>
                    <select className="select-dropdown" onChange={this.OnPriceFilterChange}>
                      <option value="">Seleccionar Precio Filtrar</option>
                      <option value="lth">Price: Low to High</option>
                      <option value="htl">Price: High to Low</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 catagory-container-right">
              <div className="listing-container">
                <ProductRightCategoryList />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  //console.log(state.UserReducer.isLoggedIn);
  return {
    isLoggedIn: state.user.isLoggedIn,
    isError: state.user.isError,
    errorMessage: state.user.errorMessage,
    dropDownBrandFilterData: state.filter.dropDownBrandFilterData,
  };
}

const actionCreators = {
  brandFilter: productsListByBrand,
  allowPurchaseFilter: productsListByAllowPurchase,
  priceRangeFilter: productsListByPriceRange,
  priceFilterByType: priceFilterByType,
  priceRangeFilterReset: resetFilterByPriceRange
};

const ProductFilterSection = connect(mapStateToProps, actionCreators)(ProductFilterSectionComp);

export default ProductFilterSection;